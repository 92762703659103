var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fit pa-2 pill-dropdown" }, [
    _c("div", { staticClass: "u-columns space-around is-vcentered" }, [
      _c(
        "div",
        { staticClass: "u-column is-narrow" },
        [
          _c("UserAvatar", {
            attrs: {
              width: _vm.widthOverride,
              height: _vm.widthOverride,
              avatar: _vm.user.avatar
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-column is-narrow" },
        [
          _c("b-icon", {
            attrs: {
              "custom-class": "body body-text mdi-18",
              icon: "chevron-down"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }