var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-container" }, [
    _c("div", { staticClass: "icon full border thick subtle-primary" }, [
      _c("img", {
        staticStyle: { width: "32px", height: "32px" },
        attrs: { src: _vm.logo }
      })
    ]),
    _c("div", {
      staticClass: "sidebar full border no-vertical thick subtle-primary"
    }),
    _c(
      "div",
      { staticClass: "header full border no-horizontal thick subtle-primary" },
      [
        _c(
          "div",
          {
            staticClass: "u-columns full-height is-vcentered space-between pa-3"
          },
          [
            _c(
              "div",
              { staticClass: "u-column is-narrow" },
              [
                _vm._t("left", function() {
                  return [
                    _c("p", { staticClass: "body bold black md ml-4" }, [
                      _vm._v("uman.ai")
                    ])
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "u-column is-narrow" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      custom: "",
                      "mobile-modal": false,
                      position: "is-bottom-left"
                    }
                  },
                  [
                    _c("UserHeaderItem", {
                      staticClass: "cursor-pointer ml-2",
                      attrs: {
                        slot: "trigger",
                        "width-override": 28,
                        user: _vm.currentUser
                      },
                      slot: "trigger"
                    }),
                    _c(
                      "b-dropdown-item",
                      {
                        staticStyle: { right: "24px" },
                        attrs: { custom: "", paddingless: "" }
                      },
                      [
                        _c("ProfileDialog", {
                          attrs: { user: _vm.currentUser },
                          on: { logout: _vm.logoutAction }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "content full" },
      [_vm._t("default", null, { currentUser: _vm.currentUser })],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }