var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DarkDialogContainer",
    {
      attrs: {
        type: "light",
        "vertical-align": "top",
        "horizontal-align": "right"
      }
    },
    [
      _c("div", { staticClass: "menu" }, [
        _c("ul", { staticClass: "menu-list" }, [
          _c("li", [
            _c(
              "p",
              {
                staticClass: "body no-padding-horizontal option",
                on: { click: _vm.openProfileSettings }
              },
              [
                _c("img", {
                  staticClass: "option-icon",
                  attrs: {
                    src: require("@/assets/icons/settings.svg"),
                    alt: ""
                  }
                }),
                _vm._v(" My settings ")
              ]
            )
          ]),
          _c("li", { class: { disabled: _vm.disableProfileButton } }, [
            _c(
              "p",
              {
                staticClass: "body no-padding-horizontal option",
                on: { click: _vm.openProfile }
              },
              [
                _c("img", {
                  staticClass: "option-icon",
                  attrs: { src: require("@/assets/icons/user.svg"), alt: "" }
                }),
                _vm._v(" My profile ")
              ]
            )
          ]),
          _c("li", [
            _c(
              "p",
              {
                staticClass: "body no-padding-horizontal option",
                on: {
                  click: function() {
                    _vm.$emit("logout")
                    _vm.$emit("click")
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "option-icon",
                  attrs: { src: require("@/assets/icons/logout.svg"), alt: "" }
                }),
                _vm._v(" Log out ")
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "divider subtle-primary no-margin mv-2" }),
      _c("div", { staticClass: "ph-4 pv-3" }, [
        _c("p", { staticClass: "body body-text" }, [
          _vm._v(
            " " + _vm._s(_vm.user.first_name + " " + _vm.user.last_name) + " "
          )
        ]),
        _c("p", { staticClass: "body body-text" }, [
          _vm._v(_vm._s(_vm.user.username))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }