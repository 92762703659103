


































import UserHeaderItem from '@c/extension/main-extension-container/molecules/UserHeaderItem.vue'
import ProfileDialog from '@c/features/profile-dialog/organisms/ProfileDialog.vue'
import User from '@c/models/User'
import Vue from 'vue'
import UmanAiLogo from '@c/assets/logo/umanai.svg'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'BareApplicationShell',
  components: { UserHeaderItem, ProfileDialog },
  props: {
    currentUser: {
      type: Object as () => User,
      default: undefined
    }
  },
  computed: {
    logo() {
      return UmanAiLogo
    }
  },
  methods: {
    ...mapActions(['logout']),

    logoutAction() {
      this.logout()
      this.$router.push('/login')
    }
  }
})
