

















import User from '@c/models/User'
import UserAvatar from '@c/shared/molecules/object-visualisations/user/UserAvatar.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'UserHeaderItem',
  components: { UserAvatar },
  props: {
    user: {
      type: Object as () => User,
      required: true
    },
    widthOverride: {
      type: Number,
      default: 20
    }
  }
})
