

















































import User from '@c/models/User'
import DarkDialogContainer from '@c/shared/molecules/structural/containers/DarkDialogContainer.vue'
import Vue, { PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileDialog',
  components: { DarkDialogContainer },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspaceMember']),
    disableProfileButton() {
      return (
        this.$route.name === 'member-profile' &&
        (this.$route.params.member_id === 'me' ||
          this.$route.params.member_id === this.currentWorkspaceMember.uuid)
      )
    }
  },
  methods: {
    ...mapActions(['pushBreadCrumb']),
    openProfileSettings() {
      this.$modals.user.openProfileSettings(this, this.user)
      this.$emit('click')
    },
    openProfile() {
      if (!this.disableProfileButton) {
        this.pushBreadCrumb({ ...this.$route })
        this.$router.push({
          name: 'member-profile',
          params: {
            workspace_id: this.$route.params.workspace_id,
            member_id: 'me'
          }
        })
        this.$emit('click')
      }
    }
  }
})
